import { useState, useEffect, createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { TopNavbar, Navbar } from '../components';
import axios from 'axios';
import connection from '../signalrConnection';
import '../assets/css/Navbar.css';
import authContext from "../utils/authContext";

export const DataContext = createContext();

function Layout() {
  const [username, setUsername] = useState('');
  const { authenticated } = useContext(authContext);

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/api/tenant',
      withCredentials: true
    })
      .then((response) => {
        setUsername(', ' + response.data.tenantName + '!')
        connection.start();
      })
      .catch(() => {
        setUsername('');
        connection.stop();
      })
  }, [authenticated]);

  return (
    <DataContext.Provider value={username}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-2 px-0'>
            <Navbar />
          </div>
          <div className='col-sm-10 px-0' id='content' style={{ position: 'relative' }}>
            <TopNavbar />
            <Outlet />
          </div>
        </div>
      </div>
    </DataContext.Provider>
  );
}

export default Layout;