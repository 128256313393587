import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../assets/css/Companies.css';
import { EventContext } from '../App';
import { useEventListener } from '../utils/customHooks';
import Select from 'react-select';
import noElementsImage from '../app/webcomponents/assets/images/empty-states_illustration_first-use.svg';

const constantValues = {
  createNewCompany: 'create a new company',
  changeAssociation: 'change association',
  deleteAssociation: 'delete association',
  excludeFromAutoSync: 'Excluded',
  createNewCompanyLabel: 'Set as a new GravityZone company',
  deleteAssociationLabel: 'Delete association',
  excludeFromAutoSyncLabel: 'Exclude from Automatic Sync',
  removeExclusionLabel: 'Remove exclusion'
};

const Companies = props => {

  const [data, setData] = useState({ associations: null, gzCompanies: null });
  const [events, setEvents] = useState([]);
  //selectResult = 1 there is already a company in gz with that name
  //selectResult = 2 there is no company in gz with that name
  const [selectResult, setSelectResult] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [gzCompaniesWithStatus, setGzCompaniesWithStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [taskStatus, setTaskStatus] = useState(-1);
  const taskResults = useContext(EventContext);

  const toastNotification = (message, severity) => {
    const toastNotificationConfigs = {
      message: message,
      severity: severity,
      x: 'right',
      y: 'bottom',
      autoCloseDelayMs: 3000,
    };

    window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
  }

  const handleSelect = (gzCompany, dattoCompany, dattoCompanyId, oldGzCompanyName, oldGzCompanyId) => {
    let existingCompany = data.gzCompanies.filter(company => company.name === dattoCompany);
    if (gzCompany === null) {
      if (oldGzCompanyName !== constantValues.createNewCompanyLabel && oldGzCompanyName !== constantValues.excludeFromAutoSyncLabel) {
        let oldElement = gzCompaniesWithStatus.filter((el) => el.id === oldGzCompanyId);
        setOptions([...options.filter(option => option.dattoSiteId !== dattoCompanyId),
        {
          'dattoSite': dattoCompany,
          'dattoSiteId': dattoCompanyId,
          'gzCompanyName': constantValues.deleteAssociationLabel
        }]);

        setGzCompaniesWithStatus(gzCompaniesWithStatus.map(
          el => el.id === oldElement[0].id ? { ...el, selected: false } : el
        ));
        setData({
          ...data, associations: data.associations.map((item) => (
            {
              ...item,
              gravityZoneCompanyName:
                item.gravityZoneCompanyId === oldGzCompanyId ? '' : item.gravityZoneCompanyName,
              gravityZoneCompanyId:
                item.gravityZoneCompanyId === oldGzCompanyId ? null : item.gravityZoneCompanyId,
            }))
        })
        setSelectResult(2);
      } else {
        setData({
          ...data, associations: data.associations.map((item) => (
            {
              ...item,
              gravityZoneCompanyName:
                item.rmmCompanyId === dattoCompanyId ? '' : item.gravityZoneCompanyName,
              gravityZoneCompanyId:
                item.rmmCompanyId === dattoCompanyId ? null : item.gravityZoneCompanyId,
            }))
        })
        setSelectResult(2);
        if (initialData.find(el => el.rmmCompanyId === dattoCompanyId) !== undefined) {
          setOptions([...options.filter(option => option.dattoSiteId !== dattoCompanyId),
          {
            'dattoSite': dattoCompany,
            'dattoSiteId': dattoCompanyId,
            'gzCompanyName': constantValues.deleteAssociationLabel
          }]);
          setSelectResult(2);
        } else if (oldGzCompanyName === constantValues.excludeFromAutoSyncLabel) {
          setOptions([...options,
            {
              'dattoSite': dattoCompany,
              'dattoSiteId': dattoCompanyId,
              'gzCompanyName': constantValues.removeExclusionLabel
            }]);
            setSelectResult(2);
        } else {
          setOptions([...options,
          {
            'dattoSite': dattoCompany,
            'dattoSiteId': dattoCompanyId,
            'gzCompanyName': constantValues.deleteAssociationLabel
          }]);
          setSelectResult(2);
        }
      }
    } else if (existingCompany.length !== 0 && gzCompany.value === constantValues.createNewCompany) {
      setSelectResult(1);
    } else if (gzCompany !== null) {
      if (gzCompany.value === constantValues.createNewCompany || gzCompany.value === constantValues.excludeFromAutoSync) {
        setOptions([...options.filter(option => option.dattoSiteId !== dattoCompanyId),
        {
          'dattoSite': dattoCompany,
          'dattoSiteId': dattoCompanyId,
          'gzCompanyName': gzCompany.label
        }]);
        setSelectResult(2);
      } else if (gzCompany.title === oldGzCompanyName) {
        return;
      } else {
        setOptions([...options.filter(option => option.dattoSite !== dattoCompany),
        {
          'dattoSite': dattoCompany,
          'dattoSiteId': dattoCompanyId,
          'gzCompanyName': gzCompany.name,
          'gzCompanyId': gzCompany.id
        }]);
        setSelectResult(2);
      }

      let oldElement;
      let newElement;

      if (oldGzCompanyName !== '' && oldGzCompanyName !== null) {
        if (gzCompany.value !== constantValues.createNewCompany && gzCompany.value !== constantValues.excludeFromAutoSync) {
          if (oldGzCompanyName !== constantValues.createNewCompanyLabel && oldGzCompanyName !== constantValues.excludeFromAutoSyncLabel) {
            oldElement = gzCompaniesWithStatus.filter((el) => el.id === oldGzCompanyId);
            newElement = gzCompaniesWithStatus.filter((el) => el.id === gzCompany.id);
            setGzCompaniesWithStatus(gzCompaniesWithStatus.map(
              el => el.id === oldElement[0].id ? { ...el, selected: false } :
                el.id === newElement[0].id ? { ...el, selected: true } : el
            ));

            setData({
              ...data, associations: data.associations.map((item) => (
                {
                  ...item,
                  gravityZoneCompanyName:
                    item.gravityZoneCompanyId === oldGzCompanyId ? newElement[0].name : item.gravityZoneCompanyName,
                  gravityZoneCompanyId:
                    item.gravityZoneCompanyId === oldGzCompanyId ? newElement[0].id : item.gravityZoneCompanyId,
                }))
            })
            setSelectResult(2);
          } else {
            newElement = gzCompaniesWithStatus.filter((el) => el.id === gzCompany.id);
            setData({
              ...data, associations: data.associations.map((item) => (
                {
                  ...item,
                  gravityZoneCompanyName:
                    item.rmmCompanyId === dattoCompanyId ? newElement[0].name : item.gravityZoneCompanyName,
                  gravityZoneCompanyId:
                    item.rmmCompanyId === dattoCompanyId ? newElement[0].id : item.gravityZoneCompanyId,
                }))
            })
            setSelectResult(2);
          }
        } else if ((gzCompany.value === constantValues.createNewCompany || gzCompany.value === constantValues.excludeFromAutoSync)
          && gzCompany.label !== oldGzCompanyName
          && (oldGzCompanyName !== constantValues.createNewCompanyLabel && oldGzCompanyName !== constantValues.excludeFromAutoSyncLabel)) {
          oldElement = gzCompaniesWithStatus.filter((el) => el.id === oldGzCompanyId);
          setGzCompaniesWithStatus(gzCompaniesWithStatus.map(
            el => el.id === oldElement[0].id ? { ...el, selected: false } : el
          ));

          setData({
            ...data, associations: data.associations.map((item) => (
              {
                ...item,
                gravityZoneCompanyName:
                  item.gravityZoneCompanyId === oldGzCompanyId ?
                    gzCompany.value === constantValues.createNewCompany ?
                      constantValues.createNewCompanyLabel : constantValues.excludeFromAutoSyncLabel :
                    item.gravityZoneCompanyName,
                gravityZoneCompanyId:
                  item.gravityZoneCompanyId === oldGzCompanyId ? null : item.gravityZoneCompanyId,
              }))
          })
          setSelectResult(2);
        }
        if ((oldGzCompanyName === constantValues.createNewCompanyLabel || oldGzCompanyName === constantValues.excludeFromAutoSyncLabel)
          && gzCompany.label !== oldGzCompanyName
          && (gzCompany.value !== constantValues.createNewCompany && gzCompany.value !== constantValues.excludeFromAutoSync)) {
          newElement = gzCompaniesWithStatus.filter((el) => el.id === gzCompany.id);
          setGzCompaniesWithStatus(gzCompaniesWithStatus.map(
            el => el.id === newElement[0].id ? { ...el, selected: true } : el
          ));
          setData({
            ...data, associations: data.associations.map((item) => (
              {
                ...item,
                gravityZoneCompanyName:
                  item.rmmCompanyId === dattoCompanyId ? newElement[0].name : item.gravityZoneCompanyName,
                gravityZoneCompanyId:
                  item.rmmCompanyId === dattoCompanyId ? newElement[0].id : item.gravityZoneCompanyId,
              }))
          })
          setSelectResult(2);
        } else if ((oldGzCompanyName === constantValues.createNewCompanyLabel || oldGzCompanyName === constantValues.excludeFromAutoSyncLabel)
          && gzCompany.label !== oldGzCompanyName
          && (gzCompany.value === constantValues.createNewCompany || gzCompany.value === constantValues.excludeFromAutoSync)) {
          if (gzCompany.value === constantValues.createNewCompany) {
            setData({
              ...data, associations: data.associations.map((item) => (
                {
                  ...item,
                  gravityZoneCompanyName:
                    item.rmmCompanyId === dattoCompanyId ? constantValues.createNewCompanyLabel : item.gravityZoneCompanyName,
                  gravityZoneCompanyId:
                    item.rmmCompanyId === dattoCompanyId ? null : item.gravityZoneCompanyId,
                }))
            });
          } else if (gzCompany.value === constantValues.excludeFromAutoSync) {
            setData({
              ...data, associations: data.associations.map((item) => (
                {
                  ...item,
                  gravityZoneCompanyName:
                    item.rmmCompanyId === dattoCompanyId ? constantValues.excludeFromAutoSyncLabel : item.gravityZoneCompanyName,
                  gravityZoneCompanyId:
                    item.rmmCompanyId === dattoCompanyId ? null : item.gravityZoneCompanyId,
                }))
            });
          }
          setSelectResult(2);
        }
      } else {
        if (gzCompany.value !== constantValues.createNewCompany && gzCompany.value !== constantValues.excludeFromAutoSync) {
          newElement = gzCompaniesWithStatus.filter((el) => el.id === gzCompany.id);
          setGzCompaniesWithStatus(gzCompaniesWithStatus.map(
            el => el.id === newElement[0].id ? { ...el, selected: true } : el
          ));
          setData({
            ...data, associations: data.associations.map((item) => (
              {
                ...item,
                gravityZoneCompanyName:
                  item.rmmCompanyId === dattoCompanyId ? newElement[0].name : item.gravityZoneCompanyName,
                gravityZoneCompanyId:
                  item.rmmCompanyId === dattoCompanyId ? newElement[0].id : item.gravityZoneCompanyId,
              }))
          })
          setSelectResult(2);
        } else {
          if (gzCompany.value === constantValues.createNewCompany) {
            setData({
              ...data, associations: data.associations.map((item) => (
                {
                  ...item,
                  gravityZoneCompanyName:
                    item.rmmCompanyId === dattoCompanyId ? constantValues.createNewCompanyLabel : item.gravityZoneCompanyName,
                  gravityZoneCompanyId:
                    item.rmmCompanyId === dattoCompanyId ? null : item.gravityZoneCompanyId,
                }))
            });
          } else if (gzCompany.value === constantValues.excludeFromAutoSync) {
            setData({
              ...data, associations: data.associations.map((item) => (
                {
                  ...item,
                  gravityZoneCompanyName:
                    item.rmmCompanyId === dattoCompanyId ? constantValues.excludeFromAutoSyncLabel : item.gravityZoneCompanyName,
                  gravityZoneCompanyId:
                    item.rmmCompanyId === dattoCompanyId ? null : item.gravityZoneCompanyId,
                }))
            });
          }
          setSelectResult(2);
        }
      }
    }
  }

  const displayAssociations = () => {
    if (data.associations && data.associations.length !== 0) {
      let selectOptions = gzCompaniesWithStatus.filter(opt => !opt.selected);

      return data.associations.map((association, index) => {
        let customOptions = [
          {
            value: constantValues.excludeFromAutoSync,
            label: constantValues.excludeFromAutoSyncLabel,
            title: constantValues.excludeFromAutoSyncLabel
          },
          {
            value: constantValues.createNewCompany,
            label: constantValues.createNewCompanyLabel,
            title: constantValues.createNewCompanyLabel
          },
          association.gravityZoneCompanyName && (association.gravityZoneCompanyName !== constantValues.createNewCompanyLabel && association.gravityZoneCompanyName !== constantValues.excludeFromAutoSyncLabel) ?
            {
              value: association,
              label: association.gravityZoneCompanyName,
              title: association.gravityZoneCompanyName
            } : {},
          ...selectOptions,

        ];
        customOptions = customOptions.filter(value => Object.keys(value).length !== 0);
        let flag = false;
        let existingEvent = events.filter(event => event.rmmItemId === association.rmmCompanyId)
        if (existingEvent.length !== 0) {
          flag = true;
        }

        return (
          <tr key={index} id={index} style={{ backgroundColor: getAssociationOption(association)?.value === constantValues.excludeFromAutoSync ? '#d9e9ff' : '#00000000' }}>
            <td className="col-sm-auto" style={{ position: 'relative' }}>
              {flag &&
                <div className='center-icon'>
                  <gz-tooltip tooltiptext="Issue found under Events page" tooltipclass="neutral"><span className="ds-icon-notification_negative" style={{ color: " #ffa438", fontSize: '16px' }}></span></gz-tooltip>
                </div>}
            </td>
            <td className="col-sm-4 align-middle table-source" id={association.rmmCompanyId}>
              <gz-tooltip tooltiptext= {association.rmmCompanyName} tooltipclass="neutral" class="truncate-text">
                {association.rmmCompanyName}
              </gz-tooltip>
            </td>
            <td className="col-sm-3 table-source">
              <Select
                className='table-text single-select'
                classNamePrefix="react-select"
                menuPlacement={index > data.associations.length / 2 - 1 ? 'top' : 'bottom'}
                value={getAssociationOption(association)}
                isClearable
                isSearchable
                closeMenuOnSelect
                onChange={choice => handleSelect(choice, association.rmmCompanyName, association.rmmCompanyId, association.gravityZoneCompanyName, association.gravityZoneCompanyId)}
                options={customOptions}
                placeholder='Select an option'
                maxMenuHeight={150}
              />
            </td>
            <td className="col-sm-5"></td>
          </tr>
        );
      })
    }
  }

  const getAssociationOption = (association) => {
    if (association.gravityZoneCompanyName &&
      association.gravityZoneCompanyName !== constantValues.createNewCompanyLabel &&
      association.gravityZoneCompanyName !== constantValues.excludeFromAutoSyncLabel) {
      return {
        value: association.gravityZoneCompanyName,
        label: association.gravityZoneCompanyName,
      }
    } else if (association.gravityZoneCompanyName === constantValues.createNewCompanyLabel) {
      return {
        value: constantValues.createNewCompany,
        label: constantValues.createNewCompanyLabel,
      }
    } else if (association.gravityZoneCompanyName && association.gravityZoneCompanyName === constantValues.excludeFromAutoSyncLabel) {
      return {
        value: constantValues.excludeFromAutoSync,
        label: constantValues.excludeFromAutoSyncLabel
      }
    } else {
      return 'default';
    }
  }

  const sortAssociations = () => {
    const newToggle = !toggle;
    setToggle(newToggle);
    setData({
      ...data, associations: data.associations.sort((a, b) => {
        return newToggle
          ? a.rmmCompanyName.localeCompare(b.rmmCompanyName)
          : b.rmmCompanyName.localeCompare(a.rmmCompanyName)
      })
    })
  };

  const sendChanges = (e) => {
    e.preventDefault();
    var requestBody = [];

    for (const item in checkOptions()) {
      if (options[item].gzCompanyName === constantValues.deleteAssociationLabel) {
        let request = {
          rmmCompanyId: options[item].dattoSiteId,
          gzCompanyId: null
        };
        requestBody.push(request);
      } else if (options[item].gzCompanyName === constantValues.createNewCompanyLabel) {
        let request = {
          rmmCompanyId: options[item].dattoSiteId,
          gzCompanyId: '-'
        };
        requestBody.push(request);
      } else if (options[item].gzCompanyName === constantValues.excludeFromAutoSyncLabel) {
        let request = {
          rmmCompanyId: options[item].dattoSiteId,
          gzCompanyId: 'Excluded'
        };
        requestBody.push(request);
      }
      else {
        let request = {
          rmmCompanyId: options[item].dattoSiteId,
          gzCompanyId: options[item].gzCompanyId
        };
        requestBody.push(request);
      }
    }

    axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/api/site/companies/associations',
      data: requestBody,
      withCredentials: true
    })
      .then(() => {
        props.setTaskStarted(true);
        setOptions([]);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  const retrieveData = async () => {
    setLoading(true);
    const associations = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/api/site/companies/associations',
      withCredentials: true
    });
    const gzCompanies = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/api/gz/companies',
      withCredentials: true
    });
    const events = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/api/events',
      withCredentials: true
    });

    const noNullSource = associations.data.filter(q => !!q.rmmCompanyName);
    setData({ associations: noNullSource, gzCompanies: gzCompanies.data });
    setInitialData(associations.data.filter(a => a.gravityZoneCompanyId !== null));
    setEvents(events.data);

    const filteredArray = associations.data.filter(q => !!q.gravityZoneCompanyId).map(function (el) { return el.gravityZoneCompanyId });
    setGzCompaniesWithStatus(gzCompanies.data.map((company) => ({
      ...company,
      selected: filteredArray.includes(company.id) ? true : false,
      label: company.name,
      title: company.name,
      value: company.name
    })));
    setLoading(false);
  }

  const receiveResponseFromTask = () => {
    setTaskStatus(taskResults.taskStatusType);
  }

  const handleSelectionChanged = (e) => {
    if (e.detail.selectedValue == null) {
      let rmmCompanyName = e.path[7].dataset.rmmcompanyname
      let rmmCompanyId = e.path[7].dataset.rmmcompanyid
      let gzCompanyId = e.path[7].dataset.gzcompanyid
      let oldName = initialData.filter((a) => a.rmmCompanyId === rmmCompanyId)[0]?.gravityZoneCompanyName
      if (oldName === undefined) {
        oldName = options.filter((a) => a.dattoSiteId === rmmCompanyId)[0].gzCompanyName
      }
      handleSelect(null, rmmCompanyName, rmmCompanyId, oldName, gzCompanyId)
    } else {
      handleSelect(e.detail.selectedValue.option,
        e.detail.selectedValue.association.rmmCompanyName,
        e.detail.selectedValue.association.rmmCompanyId,
        e.detail.selectedValue.association.gravityZoneCompanyName,
        e.detail.selectedValue.association.gravityZoneCompanyId)
    }
  }
  useEventListener('optionSelected', handleSelectionChanged);
  useEventListener('selectionChange', handleSelectionChanged);

  const checkOptions = () => {
    let relevantOptions = [];
    for (const item in options) {
      // if we have a delete association, we need to check if the company had an association
      if (options[item].gzCompanyName === constantValues.deleteAssociationLabel) {
        relevantOptions.push(options[item]);
      } else if (
        // checks if the associtaion doesn't exist in the initial data
        initialData.filter((a) =>
          a.rmmCompanyId === options[item].dattoSiteId &&
          a.gravityZoneCompanyId === options[item].gzCompanyId
        ).length === 0
      ) {
        relevantOptions.push(options[item]);
      }
    }
    return relevantOptions;
  };

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    receiveResponseFromTask();
  }, [taskResults])

  useEffect(() => {
    setTimeout(() => {
      if (taskResults.taskStatusType === 4 || taskResults.taskStatusType === 5) {
        retrieveData();
      }
    }, 1000);
  }, [taskStatus]);

  useEffect(() => {
    setTimeout(() => {
      if (selectResult === 1 || selectResult === 2) {
        switch (selectResult) {
          case 1:
            toastNotification('A company with the same name already exists.', 'error')
            break;
          case 2:
            toastNotification('The company will be provisioned after saving the changes.', 'informative')
            break;
          default:
            break;
        }
      }
      setSelectResult(0);
    }, 1000);
  }, [selectResult]);

  let dialog;
  const modalWithBody = () => {
    let dialogBody = document.createElement('div');
    dialogBody.style.height = '200px';
    dialogBody.style.overflow = 'auto';

    let tablePrefix = `
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
    integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous">
    <table class='table table-text table-striped'>
    <thead style="background-color:white">
      <tr>
        <th scope='col'>Datto Site</th>
        <th scope='col'>GravityZone Company</th>
      </tr>
    </thead>
    <tbody>`;
    let content = checkOptions().map((option) => {
      return `<tr>
      <td>${escapeHtml(option.dattoSite)}</td>
      <td>${escapeHtml(option.gzCompanyName)}</td>
      </tr>`
    }).join('');
    let tableSuffix = `</tbody></table>`
    dialogBody.innerHTML = tablePrefix + content + tableSuffix;

    let dialogFooter = document.createElement('div');
    dialogBody.classList.add('modal-footer');
    dialogFooter.innerHTML = `
    <button id="confirmButton" type="button" class="btn btn-primary button-color rounded-pill" data-dismiss="modal">CONFIRM</button>
    <button id="cancelButton" type="button" class="btn btn-outline-secondary close-btn-color rounded-pill" data-dismiss="modal" id="confirmButton">CLOSE</button>`
    const dialogConfig = {
      onCloseHandler: closeAfterSomeEvent,
      headline: "Save changes",
      bodyContent: dialogBody,
      footerContent: dialogFooter,
      isOpen: true,
    }

    dialogFooter.querySelector('#confirmButton').addEventListener('click', () => {
      dialog.dismiss();
      dialog.emitDialogEvent('confirm');
    })

    dialogFooter.querySelector('#cancelButton').addEventListener('click', () => {
      dialog.dismiss();
      dialog.emitDialogEvent('cancel');
    })

    dialog = window.nirvana.dependencies.services.buildDialogElement(dialogConfig);
    dialog.addEventListener('confirm', (e) => {
      sendChanges(e);
    });
    dialog.addEventListener('cancel', () => {
      console.log("Dialog cancelled")
    });
  }

  const closeAfterSomeEvent = () => {
    dialog.dismiss();
  }

  const escapeHtml = (unsafe) => {
    return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
  }

  return (
    <div style={{ overflowY: 'scroll', height: '90vh' }}>
      <div className='mb-3 pb-3 mx-0 mr-0'>
        <div className='m-4'>
          <gz-title fontsize="20" fontweight="500" style={{ opacity: '0.7' }} id='company-page'>Companies</gz-title>
        </div>
        {loading ?
          <div className='center-div'>
            <gz-progress-spinner indeterminate="" spinnerwidth="medium"></gz-progress-spinner>
          </div> :
          <div style={{ marginBottom: '1.5rem' }}>
            {data.associations && data.associations.length !== 0 ?
              <table className='table table-hover table-responsive table-text pb-5'>
                <thead>
                  <tr id='table-head ' >
                    <th scope='col' className='float-end pb-3'>
                      <div style={{ cursor: 'pointer' }} onClick={sortAssociations}>
                        <span className="icon ds-icon-sorting_unsorted" style={{ fontSize: '24px', color: '#7f7f7f' }}></span>
                      </div>
                    </th>
                    <th scope='col'>Datto Sites<br /><gz-text fontweight='300' fontsize='12px'>Source</gz-text></th>
                    <th scope='col'>GravityZone Companies<br /><gz-text fontweight='300' fontsize='12px'>Destination</gz-text></th>
                    <th scope='col' className='pb-3'>
                      <div style={{ float: 'inline-end', cursor: 'pointer' }} onClick={retrieveData}>
                        <span className="icon ds-icon-refresh" style={{ fontSize: '24px', color: '#7f7f7f' }}></span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayAssociations()}
                </tbody>
              </table>
              :
              <div className='center-div'>
                <img src={noElementsImage} />
                <gz-title fontweight="500" fontsize="20" style={{ marginBottom: '10px' }}>No results were found!</gz-title>
                <gz-text fontsize="13" style={{ marginBottom: '5px' }} fontweight="400">Missing settings!</gz-text>
                <a href='/integrations' className='modal-subtitle-size '>Go to Settings</a>
              </div>
            }
          </div>
        }
      </div>
      {!loading && data.associations && data.associations.length !== 0 ?
        <div className='page-footer fixed-bottom-navbar'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-sm-3 navbar-light nav-bottom-shadow py-3' onClick={() => { modalWithBody() }} style={{ pointerEvents: checkOptions().length === 0 ? 'none' : 'auto' }}>
                <gz-button
                  type="full"
                  color="primary"
                  buttonheight="medium"
                  buttonfontsize="medium"
                  id='save-company-association-btn'
                  {...(checkOptions().length === 0 ? { disabled: true } : {})}>
                  SAVE CHANGES
                </gz-button>
              </div>
              <div className='col-sm-7 nav-bottom-shadow py-2' >
              </div>
            </div>
          </div>
        </div> : null}
    </div>
  );
}

export default Companies;