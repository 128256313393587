import React, {forwardRef} from 'react'

const GzFormField = (props, innerRef) => {
    return (
        <gz-form-field label={props.label} id={props.formFieldId} ref={innerRef} required>
            <gz-single-select
                id={props.id}
                gz-form-input
                labelWidth="large"
                placeholder={props.placeholder}
                errortooltiptext="Invalid value"
                filterOptions={JSON.stringify(props.filterOptions)}
                {...(props.disableCondition === true ? { disabled: true } : {})}>
            </gz-single-select>
        </gz-form-field>
    )
}

export default forwardRef(GzFormField);
