import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { Companies, Layout, Login, HandleEvents, NotFound, SettingsTab,
    ConfigureDefaultPackageTab, SystemSettings, InventorySync } from './pages';
import SetupInterceptors from "./components/SetupInterceptors";
import { useState, createContext, useEffect } from "react";
import connection from './signalrConnection';
import authContext from './utils/authContext';
import './fonts.scss';

export const EventContext = createContext();

function NavigateFunctionComponent(props) {
  let navigate = useNavigate();
  const [ran, setRan] = useState(false);

  if (!ran) {
    SetupInterceptors(navigate);
    setRan(true);
  }
  return <></>;
}

function App() {
  const [taskResult, setTaskResult] = useState({});
  const [taskStarted, setTaskStarted] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    connection.on('TaskResult', data => {
      setTaskResult(data);
    });
  }, [])

  const toastNotification = (message, severity) => {
    const toastNotificationConfigs = {
      message: message,
      severity: severity,
      x: 'right',
      y: 'bottom',
      autoCloseDelayMs: 5000,
    };

    window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
  }

  if (taskStarted) {
    toastNotification('Changes successfully saved.', 'informative')
    setTaskStarted(false);
  }

  useEffect(() => {
    setTaskStarted(false);
    setTimeout(() => {
      if (taskResult.taskStatusType === 4 || taskResult.taskStatusType === 5) {
        switch (taskResult.taskStatusType) {
          case 4:
            if (taskResult.generatedEvents <= taskResult.handledEvents) {
              toastNotification('Changes have been saved.', 'success')
            } else {
              toastNotification('New events generated.', 'warning')
            }
            break;
          case 5:
            toastNotification('Changes not saved.', 'error')
            break;
          default:
            break;
        }
        setTaskResult({});
      }
    }, 1000);
  }, [taskResult]);

  return (
    <BrowserRouter>
      <NavigateFunctionComponent />
      <authContext.Provider value={{ authenticated, setAuthenticated }}>
        <EventContext.Provider value={taskResult}>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<Companies />}></Route>
              <Route path='/companies' element={<Companies setTaskStarted={setTaskStarted} />}></Route>
              <Route path='/integrations' element={<SettingsTab />}></Route>
              <Route path='/defaultpackage' element={<ConfigureDefaultPackageTab />}></Route>
              <Route path='/system' element={<SystemSettings />}></Route>
              <Route path='/events' element={<HandleEvents setTaskStarted={setTaskStarted} />}></Route>
              <Route path='/inventory' element={<InventorySync />}></Route>
            </Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/*' element={<NotFound />} />
          </Routes>
        </EventContext.Provider>
      </authContext.Provider>
    </BrowserRouter>
  );
}

export default App;