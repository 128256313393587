import React from 'react';
import emptyStateImage from '../app/webcomponents/assets/images/empty-states_illustration_success.svg';
import '../assets/css/NotFound.css';

function NotFound() {
  return (
    <div className='container center-div'>
      <h2 className='pb-3' >404 - This page doesn't exist</h2>
      <img width={480} src={emptyStateImage} alt="404 - This page doesn't exist" />
    </div>
  )
}

export default NotFound;
