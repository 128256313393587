import '../assets/css/Navbar.css';
import { useContext } from 'react';
import { DataContext } from '../pages/Layout';

const TopNavbar = () => {
    const username = useContext(DataContext);

    return (
        <nav className="navbar sticky-top navbar-light nav-shadow py-3">
            <div className="container-fluid justify-content-end">
                <span className='welcome-message pe-4' id='welcome-message'>Welcome{username}</span>
            </div>
        </nav>
    );
}

export default TopNavbar;