import axios from 'axios';
import connection from '../signalrConnection';

const SetupInterceptors = (navigate) => {
    axios.interceptors.response.use(
       function (response) {
           return response;
       },
       function (error) {
           if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    navigate('/login');
                    connection.stop();
                }
           }
           return Promise.reject(error);
      }
   );
};

export default SetupInterceptors;